// Primary
$v-color-black: #172B34;
$v-color-dark-grey: #57676D;
$v-color-mid-grey: #717171;
$v-color-teal: #66CCCC;
$v-color-white: #FFFFFF;
$v-color-gray-primary: #d1cfcc;


// Secondary
$v-color-green: #6DC278; // Was $v-brand-success
$v-color-orange: #FF9444; // Was $v-brand-warning
$v-color-red: #D10711; // Was $v-brand-danger
$v-color-blue: #0070F5;
$v-color-violet: #7857FF;
$v-color-yellow: #F9DB00;
$v-color-warm-orange: #F99749;
$v-color-vivid-green: #37BE5F;

// Neutral
$v-color-grey: #DDE1E2;
$v-color-cool-grey: #DAD9D6;
$v-color-light-grey: #FBFAF9;
$v-color-lighter-grey: #F7F5F3;
$v-color-warm-grey: #EAE7E3;

// Pale
$v-color-lavander: #CFC2E3;
$v-color-pale-blue-grey: #C7CED1;
$v-color-pale-green: #E2F3E4;
$v-color-pale-orange: #FFEADA;
$v-color-pale-purple: #DEC0F1;
$v-color-pale-red: #FAE6E7;
$v-color-pale-teal: #E0F5F5;
$v-color-pale-violet: #E4DDFF;

// Text
$v-color-text-primary: $v-color-black;
$v-color-text-secondary: $v-color-dark-grey; // TODO - IN DESIGN

// Border
$v-border-color: $v-color-cool-grey;
$v-base-border: 1px solid $v-border-color;

// Fade
$v-fade-start-color: $v-color-black;
$v-fade-end-color: $v-color-white;

// Links
$v-link-color: $v-color-teal;
$v-link-hover-color: $v-color-dark-grey;

// Backgrounds
$v-background-dark-grey: #798b93;
$v-background-light-grey: $v-color-light-grey;
$v-background-grey: #EDEEEF;

// Navbar
$v-navbar-background: $v-color-teal;
$v-navbar-foreground: $v-color-black;
$v-navbar-notification-count-background: #f00;
$v-navbar-notification-count-foreground: $v-color-white;
