
.confirmation-bullet:not(:last-child) {
  margin-bottom: 5px;
}

.confirmation-bullet {
  display: flex;
  align-items: flex-start;
}

.bullet-text {
  margin: 6px 10px 0 0;
  width: 100%;
}

.v-tos {
  text-decoration: underline;
  cursor: pointer;
}

.main-text {
  margin: 20px 0;
}

.final-popup-text {
  font-weight: 600;
}

