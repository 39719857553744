@import './variables';

@mixin interaction-header {
    .v-interaction-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .v-header-left,
    .v-header-right {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 42px;
    }

    .v-header-right {
        justify-content: flex-end;
    }

    .v-interaction-title {
        font-size: $v-font-size-large;
        padding-left: 15px;
    }
}

@mixin review-section-header {
    display: block;
    font-size: 20px;
    width: 100%;
    background-color: $v-color-light-grey;
    color: $v-color-black;
    height: 52px;
    padding-top: 18px;
    margin-top: 0; // override defaults set on h1, h2 etc.
    margin-bottom: 0; // override defaults set on h1, h2 etc.
    font-size: $v-font-size-18;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    padding-left: 114px;
}

@mixin review-section-content {
    padding: 20px 0;
    margin-left: 114px;
}

@mixin number-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    flex-shrink: 0;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    width: 32px;
    height: 32px;
    line-height: 28px;
    font-size: 16px;

    &--black {
        color: $v-color-white;
        background-color: $v-color-black;
        border-color: $v-color-black;
    }

    &--inactive {
        color: $v-color-white;
        background-color: $v-color-gray-primary;
        border-color: $v-color-gray-primary;
    }

    &--success {
        color: $v-color-white;
        background-color: $v-color-green;
        border-color: $v-color-green;
    }

    &--warning {
        color: $v-color-white;
        background-color: $v-color-orange;
        border-color: $v-color-orange;
    }

    &--danger {
        color: $v-color-white;
        background-color: $v-color-red;
        border-color: $v-color-red;
    }
}

@mixin missing-text {
    color: $v-color-red;
}