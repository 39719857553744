@import "./variables";
@import "./baseTooltip";

//////*  ICONS FONT  *//////
@font-face {
  font-family: 'nourish-icons';
  src: url('./../fonts/nourish_icons.eot');
  src: url('./../fonts/nourish_icons.eot') format('embedded-opentype'),
  url('./../fonts/nourish_icons.woff') format('woff'),
  url('./../fonts/nourish_icons.ttf') format('truetype'),
  url('./../fonts/nourish_icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'v-nourish-icons';
  src: url('../fonts/v-nourish-icons.eot');
  src: url('../fonts/v-nourish-icons.eot') format('embedded-opentype'),
  url('../fonts/v-nourish-icons.woff') format('woff'),
  url('../fonts/v-nourish-icons.ttf') format('truetype'),
  url('../fonts/v-nourish-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Headings

.v-h2 {
  font-size: $v-font-size-xlarge;
  font-weight: 700;
}

.v-h3 {
  font-size: $v-font-size-large;
}

.v-h4 {
  font-size: $v-font-size-medium;
}

* {
  box-sizing: border-box;
}

html {
  height  : 100%;
  overflow: hidden;
  position: relative;
}

body {
  height  : 100%;
  overflow: auto;
  position: relative;
}
// Miscellaneous

.v-hr {
  border: none;
  background: $v-color-warm-grey;
  height: 1px;
  margin: 10px 0;
}

.v-align-content-center {
  align-content: center;
  align-items: center;
  display: flex;
}

// Single Panel

.v-single-panel-body {
  background-color: white;
  border-radius: 0 0 6px 6px;
  border: 1px solid $v-color-warm-grey;
  box-sizing: border-box;
  color: $v-color-black;
  outline: none;
  text-rendering: optimizeLegibility;
  width: 100%;
  vertical-align: top;
}

.v-single-panel-header {
  align-content: center;
  align-items: center;
  background-color: $v-color-white;
  border-radius: 6px 6px 0 0;
  border: 1px solid $v-color-warm-grey;
  box-sizing: border-box;
  color: $v-color-black;
  display: flex;
  font-size: $v-font-size-standard;
  height: 100px;
  justify-content: space-between;
  line-height: 100px;
  margin-top: 52px;
  min-height: auto;
  outline: none;
  padding: 0 28px 0 28px;
  vertical-align: center;
  width: 100% !important;

  &__text {
    color: $v-color-black;
    font-size: $v-line-height-base;
    font-weight: 700;
    box-sizing: border-box;
    outline: none;
    display: inline-block;
  }
}

.v-secondary-text {
  color: $v-color-text-secondary;
  display: block;
  font-size: $v-font-size-standard;
  line-height: $v-font-size-medium;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  &--small {
    font-size: $v-font-size-small;
  }

  a {
    color: $v-color-text-secondary;
  }
}

.v-with-hover {
  background-color: rgba(102, 204, 204, 0.18);
}

.v-list-entry {
  border-radius: 4px;
  box-shadow: 0.5px 2px 4px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  display: block;
  min-height: 50px;
  position: relative;
  top: 0;
  background-color: white;
  left: 0;
  opacity: 1;
  width: 100%;

  .v-list-entry-inner {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    padding: 16px;
    position: relative;
  }
}

// TODO: We need to remove all the list entry from here, and make sure anywhere that uses these classes is using BaseListEntry component
.v-list-entry-inverted {
  color: $v-color-white;
}

.v-list-entry-inner-inverted {
  background-color: $v-color-black;
}

.v-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  margin: 2px 0 0;
  color: $v-color-text-primary;
  font-size: $v-font-size-standard;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $v-color-cool-grey;
  border-radius: 4px;
  box-shadow: $v-default-box-shadow;
  padding: 0;
}

.v-dropdown-option {
  height: 35px;
  line-height: 35px;
  width: 100%;
  padding-left: 16px;
  color: $v-color-text-primary;
  font-size: $v-font-size-standard;
  text-align: left;
  position: relative;
}

.v-dropdown-option:hover {
  background-color: $v-color-pale-teal;
  cursor: pointer;
}

.v-dropdown {
  position: relative;
}

.v-no-padding {
  padding: 0 !important;
}

.v-highlight-on-hover:hover {
  background-color: #e3f6f6 !important;
}

.v-wrapped-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// labels

.v-label {
  color: $v-color-text-secondary;
  font-size: $v-font-size-small;
  font-weight: 400;
}

.v-label:before {
  content: "";
  border-radius: 50%;
  display: inline-block;
  margin-right: 2px;
  width: 10px;
  height: 10px;
}

.v-label.success:before {
  background-color: $v-color-green;
}

.v-label.danger:before {
  background-color: $v-color-red;
}

.v-label.warning:before {
  background-color: $v-color-orange;
}
