@import './variables.scss';

@media print {
    p,
    h1,
    h2,
    h3,
    h4,
    .nsx-card__description,
    .nsx-form-group,
    .nsx-form-group__control-label {
      page-break-inside: avoid;
    }

    body, html {
      font-family: Helvetica, Arial, sans-serif;
      width: 100%!important;
      height: fit-content!important;
      margin: 0!important;
      padding: 0!important;
    }

    a:link:after, a:visited:after {
      content: "";
    }

    .vue-app {
      top: 0!important;
      //height: 100%!important;
      position: relative!important;
    }

    .main {
      margin: 0!important;
      padding: 0!important;
      width: 100%!important;
      height: 100%!important;
    }

    .sidebar, .navbar, .double-panel-left, .top-actions-bar,
    .bg-image, .bg-fade, .bg-overlay, .main-client-info-container,
    .top-right-add-btn {
      display: none!important;
      width: 0!important;
    }

    .double-panel {
      left: 0!important;
      position: relative!important;
      margin-top: 0!important;
      width: 100%!important;
      height: auto!important;
    }

    .page-container {
      &, > .row {
        &, .main {
          overflow: visible!important;
          &, > .col-xs-12 {
            height: 100%!important;
          }
        }
      }
    }

    .vue-top-actions-bar {
      display: none!important;
    }

    .v-double-panel-left {
      display: none!important;
    }

    .v-bg-image {
      display: none!important;
    }

    .v-action-div {
      display: none!important;
    }

    .v-interaction-state-buttons-wrapper * {
      border-color: transparent!important;
      visibility: visible!important;
    }

    .v-inner-flex-container {
      height: 100%!important;
    }

    .v-double-panel-right {
      float: none!important;
      left: 0!important;
      width: 100%!important;
      height: 100%!important;
      padding: 0!important;

      .inner-form, .outer-form {
        overflow-y: auto!important;
        height: auto!important;
        width: auto!important;
      }

      .floated-form-wrapper {
        display: block!important;
        margin: 0!important;
      }
    }

    table * {
      font-size: 10px!important;
    }

    td {
      page-break-inside: avoid!important;
      page-break-before: avoid!important;
      page-break-after: avoid!important;
    }

    thead { display: table-header-group; }
    tfoot { display: table-row-group; }
    tr { page-break-inside: avoid!important }

    .no-printable {
      float: none!important;
      display: block;
      overflow: visible!important;
      height: auto!important;
      position: relative;
      min-height: 8in;

      * {
        visibility: hidden;

        &.card:not(.single-view-print),
        &.nsx-card:not(.single-view-print) {
          display: none;
        }
      }

      &.modal-open {
        .modal { position: static; }
        .page-container { display: none; }
      }
    }

    .single-view-print {
      display: block!important;
      float: none!important;
      position: relative;
      margin-top: 0!important;
      visibility: visible;
      overflow: visible!important;
      width: 100%;

      * { visibility: visible; }

      &.vue-card.card-with-info-table {
        border: none!important;
        min-height: auto;

        .card-heading {
          border-bottom: none;

          .vue-card-heading-inner {
            background-color: transparent!important;
          }
        }

        .card-entry {
          padding: 0;
        }

        .editable-table {
          overflow: hidden;

          tbody {
            tr td {
              &.editable-table-actions-col {
                display: none;
              }
            }
          }
        }
      }
    }

    .client-subcategory-print {
      & + .client-subcategory-print {
        margin-top: $v-baseline*1.75;
      }
    }

    .general-info-section {
      .card-circular-header {
        margin-bottom: 0!important;
      }

      .card:nth-child(2) {
        margin-top: 0!important;
      }
    }

    .v-list-entry {
      -webkit-print-color-adjust: exact;

      .main-col {
        .main-col-wrapper {
          .sec-text {
            color: $v-color-text-secondary!important;
          }

          .sec-text-bolded {
            color: $v-color-black!important;
            font-weight: bold;
          }
        }
      }
    }

    .body-map {
      .point {
        background-color: $v-color-teal!important;
        color: #ffffff!important;
        -webkit-print-color-adjust: exact;
      }
    }

    .custom-checkbox:checked + label:after,
    .custom-checkbox:checked + .checkbox-label:after {
      -webkit-print-color-adjust: exact;
      box-shadow: inset 0 0 0 20px #1fc1c8, 0 0 0 0px #1fc1c8!important;
      background-color: $v-color-teal !important;
    }

    .custom-checkbox + label:after,
    .custom-checkbox + .checkbox-label:after {
      -webkit-print-color-adjust: exact;
      box-shadow: inset 0 0 0 20px $v-color-cool-grey, 0 0 0 0px $v-color-cool-grey!important;
      background-color: #eeeeee !important;
    }

    .custom-checkbox + label:before,
    .custom-checkbox + .checkbox-label:before {
      -webkit-print-color-adjust: exact;
      background-color: white!important;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15), 0 0 0 1px #dddddd!important;
      width: 26px;
      height: 26px;
      top: 2px;
      left: 2px;
    }

    [contenteditable=true]:empty:before {
      content: "";
    }

    .loading-reports-main {
      display: none!important;
    }

    .avatar.avatar-small {
      width: 32px!important;
      height: 32px!important;

      & + span {
        font-size: 12px!important;
      }
    }

    .messages-main {
      padding: 0;

      .single-panel-header {
        display: none;
      }
      .message-editor-container {
        overflow: visible;
        height: auto !important;
      }
      .js-full-height-list {
        height: auto !important;

        .action-btn {
          display: none;
        }
      }
    }
}

@media print {
  $card-padding-left: $v-baseline;
  $card-padding-right: $card-padding-left;
  $card-padding-top: 12px;
  $card-padding-bottom: 12px;

  .card {
    border: 1px solid $v-color-warm-grey;
    border-radius: 6px;
    margin: 0 auto $v-baseline;
    padding: 0;
    width: 100%!important;
    page-break-inside: avoid!important;
    -webkit-print-color-adjust: exact;

    .card-heading {
      background-color: $v-background-light-grey!important;
      -webkit-print-color-adjust: exact!important;
      border-bottom: 1px solid $v-color-warm-grey;
      padding: 0;

      .card-heading-inner {
        min-height: initial!important;

        padding-top: $card-padding-top + 5px;
        padding-left: $card-padding-left;
        padding-right: $card-padding-right;
        padding-bottom: $card-padding-bottom - 2px;

        .main-col {
          padding-left: calc($v-baseline / 4);
          width: 100%!important;

          .main-title {
            float: none!important;
            margin-top: 0!important;
            font-weight: bold;
            line-height: 0;
            margin-top: -3px;
          }
        }
      }

      .v-list-entry-symbol {
        vertical-align: middle;

        .icon {
          font-size: 30px;
          margin-top: -1px;
        }
      }
    }

    .card-entry,
    .card-entry.card-entry-careplan-need-review {
      font-size: $v-font-size-small!important;
      min-height: auto!important;

      padding-top: $card-padding-top!important;
      padding-left: $card-padding-left!important;
      padding-right: $card-padding-right!important;
      padding-bottom: $card-padding-bottom!important;

      .title, .primary-text {
        color: $v-color-teal!important;
        font-size: $v-font-size-standard!important;
        margin-bottom: 2px!important;
      }

      &.no-padding-bottom.no-padding-top {
        padding-top: 0!important;
        padding-bottom: 0!important;
        padding-left: $card-padding-left!important;
        padding-right: $card-padding-right!important;

        .padding-top-bottom-md {
          padding-bottom: $card-padding-bottom!important;
        }
      }

      .card-entry-icon-col {
        display: none;
      }

      .card-entry-row,
      .margin-bottom-lg,
      .margin-bottom-md {
        margin-bottom: 0!important;
      }

      .margin-top-md {
        margin-top: 3px!important;
      }

      .text-base {
        font-size: $v-font-size-small!important;
      }

      .card-entry-info-col {
        margin-left: 0!important;
        padding-left: 0!important;

        [contenteditable]:not(.as-not-editable),
        .editable-span:not(.as-not-editable) {
          margin: 0!important;
          padding: 0!important;
          min-height: auto;

          .placeholder-text {
            display: none!important;
          }
        }

        .editable-span.empty:not(.as-not-editable) {
          font-style: normal!important;
        }
      }

      .info {
        min-height: initial!important;
      }

      .round-label {
        background-color: transparent!important;
        font-size: $v-font-size-standard!important;
        color: $v-color-text-secondary!important;
        line-height: initial!important;
        width: auto!important;
        height: auto!important;
        margin-top: 0!important;

        & + span {
          display: inline!important;
          margin-top: 0 !important;
          vertical-align: middle;
        }
      }
    }

    .card-body {
      border-top: none;
    }

    .card-add-module {
      display: none;
    }

    .right-col-item, .action-btn {
      display: none!important;
    }
  }

  .card.card-circular-header {
    border: none;
    margin-top: 0!important;

    & + .card {
      margin-top: 0!important;
    }

    .main-title {
      font-size: 18px!important;
      font-weight: bold;
      margin-top: calc($v-baseline / 2);
      margin-bottom: calc($v-baseline / 4);
    }

    .header-info-block {
      display: none;
    }
  }

  .card.card-logger {
    .card.card-heading {
      background-color: white!important;
      border-bottom: none!important;

      .card-heading-inner {
        padding-left: 0;
        padding-right: 0;
        border-bottom: none;
      }

      .card-body {
        padding: 0!important;
        border-top: none!important;

        .card-entry {
          padding-left: 0!important;
          padding-right: 0!important;
          page-break-inside: auto!important;

          .card-entry-info-col {
            padding-right: 0!important;
            padding-left: 0!important;
          }
        }
      }
    }

    .alert.alert-block {
      font-size: $v-font-size-small;
      border: none;
      padding: calc($v-baseline / 4) 0;
    }
  }

  .need-review {
    font-size: $v-font-size-small!important;
    padding-top: 10px!important;
    padding-bottom: 5px!important;

    & + .need-review {
      border-color: $table-border-color!important;
      padding-top: 12px!important;
    }

    .need-review-actual-content {
      line-height: 1.2em!important;
      padding: 0!important;

      .need-review-datetime {
        margin-bottom: 6px!important;
      }

      .need-review-datetime .date,
      .need-review-datetime .time {
        i {
          font-size: $v-font-size-standard!important;
        }
      }
    }

    .need-review-info-col {
      min-height: auto!important;
    }

    .need-review-carer-col {
      .v-list-entry-symbol {
        margin-top: 0!important;
      }
    }
  }

  .card-entry-multiples {
    .card-entry-row + .card-entry-row {
      margin-top: 10px!important;
    }

    &.editable-mode {
      .delete-row-col,
      .add-row-col {
        display: none;
      }
    }
  }
}

