@import "../../_shared/styles/variables.scss";
@import "../../timeline/styles/timeline-variables.scss";
@import "../../_shared/styles/colour.scss";

.v-parameter-score {
  font-size: 10px;
  line-height: 22px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  color: white;
  display: inline-block;
  font-weight: bold;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;

  &.v-total-score {
    height: 60px;
    line-height: 60px;
    font-size: 26px;
    width: 60px;
    margin-left: -95px;
    margin-bottom: 10px;
  }
  &.v-total-score-default {
    background-color: $v-color-black;
  }

  &.v-round-label-default {
    background-color: $v-color-black;
  }

  &.v-round-label-danger, &.v-total-score-danger {
    background-color: $v-color-red;
  }

  &.v-round-label-success, &.v-total-score-success {
    background-color: $v-color-green;
  }

  &.v-round-label-warning, &.v-total-score-warning {
    background-color: $v-color-orange;
  }

  &.v-round-label-minor, &.v-total-score-minor {
    background-color: $v-color-yellow;
  }

  &.v-round-label-primary {
    background-color: $v-color-teal;
  }
}
