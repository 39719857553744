@import './variables.scss';

@font-face {
  font-family: 'v-nourish-icons';
  src:  url('../../fonts/v-nourish-icons.eot');
  src:  url('../../fonts/v-nourish-icons.eot') format('embedded-opentype'),
  url('../../fonts/v-nourish-icons.ttf') format('truetype'),
  url('../../fonts/v-nourish-icons.woff') format('woff'),
  url('../../fonts/v-nourish-icons.svg#v-nourish-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="v-icon-"], [class*=" v-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'v-nourish-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v-icon-spinner_1:before {
  content: "\e92f";
}
.v-icon-spinner_2:before {
  content: "\e950";
}
.v-icon-spinner_3:before {
  content: "\e951";
}
.v-icon-spinner_4:before {
  content: "\e952";
}
.v-icon-events:before {
  content: "\e900";
}
.v-icon-body-map:before {
  content: "\e93a";
}
.v-icon-report-circled:before {
  content: "\e95b";
}
.v-icon-add-new:before {
  content: "\e954";
}
.v-icon-add-new-outlined:before {
  content: "\e957";
}
.v-icon-launcher:before {
  content: "\e958";
}
.v-icon-medication:before {
  content: "\e955";
}
.v-icon-image-gallery:before {
  content: "\e956";
}
.v-icon-padlock:before {
  content: "\e959";
}
