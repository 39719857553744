.v-table {
    border-collapse: separate;
    border-spacing: 0;
    min-width: 100%;
    margin-bottom: 0;
    max-width: initial;
    width: auto;
    table-layout: auto;
    background-color: white;
  }

  .v-table {
    border: 1px solid $table-border-color;
    border-radius: 4px;

    thead > tr > th {
      background-color: rgba($v-color-teal, 0.2);
      border-bottom: 1px solid $table-border-color;
      color: $v-color-teal;
      font-weight: 400;
      font-size: $v-font-size-medium;
      padding: calc($v-baseline/2) $v-baseline*1.25;
      vertical-align: middle;
      text-align: center;
      height: 50px;

      & + th {
        border-left: 1px solid $table-border-color;
      }

      a {
        text-decoration: none;

        // cba to import these right now
        // &::after {
        //   @extend .fa;
        //   @extend .fa-sort, :before !optional;
        //   margin-left: 5px;
        // }

        // &.order-asc::after {
        //   @extend .fa-sort-asc, :before !optional;
        // }

        // &.order-desc::after {
        //   @extend .fa-sort-desc, :before !optional;
        // }
      }

      p {
        margin-bottom: 0;
      }
    }

    tbody {
      tr {
        &:not(:last-child) {
          td {
            border-bottom: 1px solid $table-border-color;
          }
        }

        &:nth-child(even) {
          td {
            background-color: rgba($v-color-cool-grey, 0.2);
          }
        }

        td {
          border-top: none;
          font-size: 12px;
          font-weight: 100;
          height: 50px;
          padding: calc($v-baseline/2) $v-baseline;
          text-align: center;
          vertical-align: middle;


          &.date-line {
            font-weight: 400;

            .icon {
              margin-right: $v-baseline*0.75;
            }

            span:last-child {
              color: $v-color-text-secondary;
              font-weight: 100;
            }
          }

          &:not(:first-child) {
            border-left: 1px solid $table-border-color;
          }

          &:not(:last-child) {
            text-align: center;
          }

          a:not(.action-btn):not(.nsx-icon-btn) {
            color: $v-color-text-primary;
            text-decoration: none;

            &:hover, &:active {
              color: $v-color-warm-grey;
            }
          }

          > a:not(.action-btn) {
            display: block;
          }

          p {
            margin-bottom: 0;
          }

          [contenteditable] {
            font-weight: 400;
            margin-left: 0!important;
            text-align: left;
          }
        }
      }
    }

    .person-table-column {
      width: 260px;
      text-align: left!important;
    }

    a.action-btn {
      margin: 0 auto;

      &::after {
        background: transparent!important;
      }
    }
  }

  .person-table-column__name {
    display: inline-block;
    width: calc(100% - 45px);
    float: right;
    margin-top: 10px;

    @media screen and (max-width: $v-screen-sm) {
      width: 100%;
    }
  }

  //** NOT BORDERED TABLE **//
  table.not-bordered {
    border: none;

    thead > tr > th {
      font-weight: bold;
      text-align: left;
      padding-left: 8px;
      padding-right: 8px;

      & + th {
        border-left: none;
      }
    }

    tbody {
      margin-top: 0;

      tr {
        td {
          border-bottom: 1px solid $v-color-warm-grey;
          border-left: none!important;
          padding-left: 8px;
          padding-right: 8px;
          vertical-align: middle;

          &:not(:last-child) {
            text-align: left;
          }

          &:not(:first-child) {
            border-left: none;
          }
        }
      }
    }

    tr td:last-child, tr th:last-child {
      text-align: right;
    }

  }
